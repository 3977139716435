import moment from "moment";
import firebase from "../config";
import map from "lodash/map";
import { reject } from "lodash";
const database = firebase.firestore();

function getMemberTimes(teamId, memberId, day) {
  // console.log(memberId, teamId);
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("daysData")
    .doc(memberId)
    .collection("dateId")
    .doc(day)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        console.log(data, "MEMBER DATA ");
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}
function getMemberTableData(teamId, memberId, day) {
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("tableData")
    .doc(memberId)
    .collection("dateId")
    .doc(day)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: { data, day },
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}
function getMemberWeekTimes(teamId, memberId, week) {
  const year = moment().isoWeekYear();
  week = `${week}-${year}`;
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("weeksData")
    .doc(memberId)
    .collection("weekId")
    .doc(week)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getMemberTimeData(teamId, memberId, day) {
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("daysData")
    .doc(memberId)
    .collection("dateId")
    .doc(day)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getMemberWeekTimeData(teamId, memberId, week) {
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("lastWeekData")
    .doc(memberId)
    .collection("weekId")
    .doc(week)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getMemberMonthTimeData(teamId, memberId, month) {
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("lastMonthData")
    .doc(memberId)
    .collection("monthId")
    .doc(month)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getDateRangeTimeDataMember(start, end, teamId, userId) {
  console.log(start, end, teamId, "getDateRangeTimeDataMember");
  try {
    const url = `https://us-central1-workfh-b2cee.cloudfunctions.net/dateRangeForMember`;
    const headers = new Headers();
    headers.append("Content-Type", "application/json;charset=utf-8");
    headers.append("Access-Control-Allow-Origin", "*");

    return await fetch(url, {
      headers,
      method: "POST",
      body: JSON.stringify({
        start,
        end,
        teamId,
        userId,
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        console.log("data in getDateRangeTimeDataMember", res.result);
        let data;
        if (res.success) {
          data = res.result;
          console.log("data in success", data);
          return {
            success: true,
            data: res.result,
          };
        } else {
          return {
            success: false,
            data: res.message,
          };
        }
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (err) {
    console.log(err);
  }
}

//   async function getMemberTimelog

async function getMemberTimeLog(teamID) {
  const snapshot = await database
    .collection("teamDatabase")
    .doc(teamID)
    .collection("members")
    .get();

  let data = [];
  if (!snapshot.empty) {
    const promiseAr = [];

    snapshot.forEach((doc) => {
      promiseAr.push(
        database
          .collection("teamDatabase")
          .doc(teamID)
          .collection("members")
          .doc(doc.id)
          .collection("days")
          .get()
      );
    });

    const aa = await Promise.all(promiseAr);

    aa.forEach((res) => {
      res.forEach((d) => {
        data.push({
          ...d.data(),
          date: d.id,
        });
      });
    });
    return {
      success: true,
      data,
    };
  } else {
    return { success: true, data: [] };
  }
}

async function getAllMemberData(
  teamID,
  activeTeamMembers,
  month,
  startDate,
  endDate
) {
  // Create an array of dates from startDate to endDate
  const dateArray = [];
  const currentDate = new Date(startDate);
  const end = new Date(endDate);

  while (currentDate <= end) {
    dateArray.push(currentDate.toLocaleDateString("en-GB")); // Format date as dd/mm/yyyy
    currentDate.setDate(currentDate.getDate() + 1); // Increment date by one day
  }

  const nonChicagoTeamMembers = [], chicagoTeamMembers = [];
  
  activeTeamMembers.forEach((member)=>{
    if(member?.timeZone ==="America/Chicago"){
      chicagoTeamMembers.push(member);
    } else {
      nonChicagoTeamMembers.push(member);
    }
  })

  // Collecting all promises
  const fetchPromises = nonChicagoTeamMembers.map((member) => {
    console.log(activeTeamMembers, member, "-----");
    return database
      .collection("memberData")
      .doc(teamID)
      .collection("daysData")
      .doc(member?.userUID)
      .collection("attendanceData")
      .doc(month)
      .get()
      .then((response) => {
        const memberData = {
          id: member?.userUID,
          name: member?.userName,
          data: {},
        };
        // Populate data for each date
        dateArray.forEach((date) => {
          const formatDate = date.replace(/\//g, ":");
          if (response?.data()?.[formatDate]) {
            memberData.data[formatDate] = response.data()[formatDate];
          } else {
            memberData.data[formatDate] = {}; // Return empty object if no data
          }
        });
        return memberData;
      })
      .catch((err) => {
        // Return empty data if there's an error
        const memberData = {
          id: member?.userUID,
          name: member?.userName,
          data: {},
        };
        dateArray.forEach((date) => {
          memberData.data[date] = {}; // Populate with empty data
        });
        return { ...memberData, error: err };
      });
  });

  const allTimeZoneData = [];
  const formattedDates = dateArray.map(date=>{
    const localDate = date.split("/");
    const day = Number(localDate[0]);
    const month = Number(localDate[1]);
    const year = localDate[2];
    return `${day}:${month}:${year}`;
  })

  const fetchPromises2 = chicagoTeamMembers
  .map(async (member)=>{
    const memberId = member?.userUID
    console.log(`Processing member: ${memberId}`);
    const daysSnapshot = await database
      .collection('teamDatabase')
      .doc(teamID)
      .collection('members')
      .doc(memberId)
      .collection('days')
      .get();
    const userData = [];

    const filteredSnapshotDocs = daysSnapshot.docs.filter(
      doc=>formattedDates.includes(doc.id)
    );

    filteredSnapshotDocs.sort((a, b) => {
      const dateA = new Date(a.id.split(':').reverse().join('-')); // Convert "1:11:2024" to "2024-11-01"
      const dateB = new Date(b.id.split(':').reverse().join('-')); // Convert "2:11:2024" to "2024-11-02"
      return dateA - dateB; // Compare the dates
    });

    for (const dayDoc of filteredSnapshotDocs) {
      if (!dayDoc.data()) continue;
      const dayData = dayDoc.data();
      userData.push(dayData);
    }
    const data = {
      id: memberId,
      name: member.userName,
      data: userData,
    };
    allTimeZoneData.push(data);
    return data;
  })
  // Waiting for all fetch operations to complete
  Promise.all(fetchPromises2).then().catch(err => console.error(err));
  return Promise.all(fetchPromises)
    .then((allData) => {
      return { success: true, data: { allData, allTimeZoneData } }; // This array contains all the data fetched
    })
    .catch((err) => {
      return { success: false, data: [] }; // Return an empty array in case of error
    });
}

export default {
  getAllMemberData,
  getMemberTimes,
  getMemberWeekTimes,
  getMemberTableData,
  getMemberTimeData,
  getMemberWeekTimeData,
  getMemberMonthTimeData,
  getMemberTimeLog,
  getDateRangeTimeDataMember,
};
