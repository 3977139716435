import { FormFeedback } from 'reactstrap';
import React from 'react';
import _ from 'lodash';

/**
 * ErrorRenderer function
 * @param {*} errors - Errors Object
 * @param {*} fieldName - Field name 
 * @returns - FormFeedback with message
 */

export const errorRenderer = (errors, fieldName) => {

    return (errors && errors[fieldName]) ? <FormFeedback className="d-block">
        {errors[fieldName].message}
    </FormFeedback> : undefined

}


// @@ React-Select Error styles
export const reactSelectErrorStyles = {
    control: (provided) => ({
        ...provided,
        border: '1px solid #ea5455 !important'
    })
}


export const generateDistinctColor = (str) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

// ** Function to extract initials from content
export const getInitials = str => {
    const results = []
    const wordArray = str.split(' ')
    wordArray.forEach(e => {
        results.push(e[0])
    })
    return results.join('')
}

export const currentCompany = () => {
    let cid = localStorage.getItem('cid');
    return !_.isEmpty(cid) ? cid : '';
}

export const getFormattedDate = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if(day < 10) day = `0${day}`;
    if(month < 10) month = `0${month}`;
    return `${day}:${month}:${year}`;
}

export function convertMinutes(inputMinutes) {
    let hours = Math.floor(inputMinutes / 60);
    let minutes = Math.floor(inputMinutes % 60);
    let seconds = Math.round((inputMinutes % 1) * 60);
    if(isNaN(hours)) hours = 0;
    if(isNaN(minutes)) minutes = 0;
    if(isNaN(seconds)) seconds = 0;
    return { hours, minutes, seconds };
}

export const getTimeByDateString = (dateString) => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    const hour = dateString.slice(8, 10);
    const minute = dateString.slice(10, 12);
    const second = dateString.slice(12, 14);

    const localeTimeString = new Date(
      `${year}-${month}-${day}T${hour}:${minute}:${second}`
    ).toLocaleTimeString()
    return localeTimeString
}